import './Webinar.scss';
import React from 'react';
import Container from '../../Container';
import Typography from '../../Typography';
import Button from '../../Button';

interface WebinarProps {
  backgroundImage: string;
  image: string;
  href: string;
  description: string;
}

const Webinar: React.FC<WebinarProps> = (props) => {
  const { image, backgroundImage, href, description } = props;

  return (
    <Container>
      <div className="TDB-DataTypes__Webinar">
        <div className="TDB-DataTypes__Webinar__BackgroundImage" style={{ backgroundImage: `url(${backgroundImage})` }} />
        <div className="TDB-DataTypes__Webinar__Content">
          <div className="TDB-DataTypes__Webinar__Content__TextWrapper">
            <div className="TDB-DataTypes__Webinar__Text">
              <Typography color="neutral-main" className="TDB-DataTypes__Webinar__Text__Title" fontSize="heading-1" fontWeight="bold">
                Featured Webinar
              </Typography>
              <Typography color="neutral-main" className="TDB-DataTypes__Webinar__Text__Paragraph" fontSize="body-lg" fontWeight="medium">
                {description}
              </Typography>
            </div>
            <Button primary href={href}>
              Learn more
            </Button>
          </div>
          {image && <div className="TDB-DataTypes__Webinar__Image" style={{ backgroundImage: `url(${image})` }} />}
        </div>
      </div>
    </Container>
  );
};

export default Webinar;
