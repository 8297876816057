import React from 'react';
import IndustryDataTypeTemplate from '@components/IndustryDataType/IndustryDataTypeTemplate';
import Webinar from '@components/IndustryDataType/Webinar';
import { dataTypes, industries } from '@constants/industriesAndDatatypes';
import BackgroundImage from './assets/Healthcare_Cover.jpg';
import featuredWebinar from './assets/Featured_webinar.png';
import webinarBackgroundImage from './assets/webinar_background.jpg';
import metaImage from './assets/meta.jpg';

const HealthcarePage: React.FC = () => {
  return (
    <IndustryDataTypeTemplate
      pageName="industry-healthcare"
      helmet={{
        title: 'TileDB for Healthcare and Life Sciences',
        description: 'Use TileDB to enable precision medicines and gain faster inpatient insight.',
        shareImage: {
          url: metaImage,
          width: 1200,
          height: 627,
        },
      }}
      header="Healthcare and Life sciences"
      title="Enable precision medicine and cure diseases with faster inpatient insight"
      heroImage={BackgroundImage}
      icon={industries.healthcare.icon}
      overview={
        <>
          <p>
            Precision medicine is revolutionizing healthcare across aspects of disease prediction, prevention and treatment - be it newborn
            screening, genomic sequencing for cancer, diabetes monitoring or personalized drug regimens.
          </p>
          <p>
            TileDB is an enterprise-ready solution for providers to handle and analyze massive volumes of images, tables, videos, transcript
            data, functional genomics, genomic variants, and flat files at scale, all on a single platform. Leading scientists,
            bioinformaticians, and clinicians use TileDB today to securely access and share important and vast datasets, and run and share
            analyses to deliver insights to impact inpatient care.
          </p>
        </>
      }
      relatedItems={[dataTypes.populationGenomics, dataTypes.singleCell, dataTypes.dataframes, dataTypes.opticalImaging]}
    >
      <Webinar
        image={featuredWebinar}
        backgroundImage={webinarBackgroundImage}
        description="Hear Dr. Stephen Kingsmore of Rady Children’s Hospital share how the TileDB technology is critical to Rady Hospital’s newborn screening program and how TileDB facilitates the analysis of large genomic datasets to 72 partner sites."
        href="https://tiledb.com/blog/population-genomics-is-a-data-management-problem"
      />
    </IndustryDataTypeTemplate>
  );
};

export default HealthcarePage;
